export const data = [
  {'name': 'Home'},
  {'name': 'Desks'},
  {'name': 'Chairs'},
  {'name': 'Books'},
  {'name': 'Accessories'},
  {'name': 'Stationery'},
  {'name': 'Storage'},
  {'name': 'Aboutus'},
  {'name': 'Contactus'},
  {'name': 'Search'},
  {'name': 'Blog'},
  {'name': 'faq'},
  {'name': 'help'},
  {'name': 'support'},
  {'name': 'terms'},
  {'name': 'contract'},
  {'name': 'privacy'}
]
export const majorcategory = [
  {'name': 'Desks'},
  {'name': 'Chairs'},
  {'name': 'Books'},
  {'name': 'Accessories'},
  {'name': 'Stationery'},
  {'name': 'Storage'},
]
export const category = [
]

