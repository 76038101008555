// import store from '@/store'
import Product from '@/views/admin/Products/index'
// import Category from '@/components/datatables/category'
// import Products from '@/components/datatables/product'
import ProductForm from '@/views/admin/Products/form'
import ProductShow from '@/views/admin/Products/show'
import ProductEdit from '@/views/admin/Products/edit'
import Inventory from '@/views/admin/inventory/index'
import Inventoryform from '@/views/admin/inventory/index'
import AdminGuard from '@/helpers/guard'

export const products = [
  {path: "/products",component: Product, name: "product", beforeEnter: AdminGuard },

{path: "/products/create",component: ProductForm, beforeEnter: AdminGuard },

{path: "/products/show/:code",component: ProductShow, beforeEnter: AdminGuard },
{path: "/products/:code/edit",component: ProductEdit, beforeEnter: AdminGuard },

{path: "/invetory/update",component: Inventory, beforeEnter: AdminGuard },
{path: "/invetory/update/:code",component: Inventoryform, beforeEnter: AdminGuard },

]
