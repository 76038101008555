<template> 
<v-container>
                <v-row> 
               
  <v-card v-if="form"
            class="mx-auto"
          >
           <!-- <div
           class="height=300px"
           style="background:#CFD8DC"
           >
            <div class="d-flex justify-center">
              
            </div>  
            </div> -->
            <v-container>
            <v-row>
              <v-col md="6">
            <v-card-title class="display-1"> Name:   {{form.name}}</v-card-title>
            <v-card-title >Title:   {{form.title}}</v-card-title>
            <v-card-title >Meta:   {{form.meta}}</v-card-title>
            <v-card-title >Altertag:   {{form.altertag}}</v-card-title>
            <v-card-title >Category:   {{form.category}}</v-card-title>
            <v-card-title >Major category:   {{form.majorcategory}}</v-card-title>
            <v-card-title >Colors</v-card-title>
            <div v-if="form.colors">
              <ul v-for="(item,i) in form.colors" :key="i">
                  <li>{{item.name}}</li>
              </ul>
            </div>
            <v-card-title >Specs</v-card-title>
            <div v-if="form.services">
              <ul v-for="(item,i) in form.services" :key="i">
                  <li>{{item.name}} at ${{item.price}}</li>
              </ul>
            </div>
            <!-- <v-card-title >Description:</v-card-title>
              <p v-html="form.description"></p>  -->
              
              </v-col>
                 <v-col
                  cols="12"
                  md="6"
                  v-if="form.images.length > 0"
                  >  
                  <v-card v-for="(img, i) in form.images" :key="i" >
                  <v-img
                    :src="`${host}/${img.name}`"
                    contain
                    max-height="200"
                    class="grey darken-4"
                  ></v-img>
                </v-card>
                  </v-col></v-row>
                  <v-row>
                 <v-col
                  cols="12"
                  md="12">
                  <h2>Footer</h2>
              <p v-html="form.footer"></p> 

                    </v-col>
                  </v-row>
            </v-container>

          <div class="text-center mt-3">
                      <v-btn class="ma-3" dark @click="Back()">Back</v-btn>
                      <v-btn class="ma-3" color="blue accent-3" dark @click="Edit(form.code)">Edit</v-btn>
                    </div>
          </v-card>
  </v-row>
</v-container>
</template>
<script>
 import axios from '@/axios'
import cons from '@/helpers/myconstants'


export default {
  name: "Customershow",
  data(){
    return{
        form:{
            name:'',
            title:'',
            description:'',
            services:[],
            colors:[],
            images:[]
        },
        show: false,
        errs:{},
        option:{},
        services:[],
        title:'Create',
        init:'api/products',
        redirect: '/products',
        store: 'api/products',
        method: 'post',
        snackbar:false,
        timeout:3000
    }
  },
  created(){
      this.fetchData()
      this.host = cons.host
  },
  methods:{
    Edit(){
      this.$router.push(`${this.redirect}/${this.$route.params.code}/edit`)
    },
    Back(){
      this.$router.push(this.redirect)
    },
  
  async fetchData(){
      try{
              this.$store.commit("setLoaderTrue")
          const {data} = await axios.get(`${this.store}/${this.$route.params.code}`)
          this.form = data
          if (data.services == undefined){
            this.form.services = []
            this.$store.commit("setLoader")
            return
          }
          if (data.colors == undefined){
            this.form.colors = []
            this.$store.commit("setLoader")
            return
          }
          this.form.colors = data.colors
          this.form.services = data.services

          // console.log(this.services)
                this.$store.commit("setLoader")
        }catch(err){
         this.snackbar = true
        //   console.log(err)
        this.errs = err.response.data
                this.$store.commit("setLoader")
        }
    }
  },    
  }
</script>

<style>

.green{
color: green;
}
</style>