var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"fill-height"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-card',{staticClass:"elevation-12"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-row',{staticClass:"fill-height"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card-text',{staticClass:"mt-12"},[_c('h1',{staticClass:"text-center display-2 teal--text text--accent-3"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),[_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"value":true,"right":"","shaped":"","bottom":"","dismisable":"","color":"red"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('h2',[_vm._v(_vm._s(_vm.errs.error))]),_c('p',[_vm._v(_vm._s(_vm.errs.message))])])],_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":" first Name","required":""},model:{value:(_vm.form.f_name),callback:function ($$v) {_vm.$set(_vm.form, "f_name", $$v)},expression:"form.f_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Last name","error-messages":errors,"required":""},model:{value:(_vm.form.l_name),callback:function ($$v) {_vm.$set(_vm.form, "l_name", $$v)},expression:"form.l_name"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"User Name","error-messages":errors,"required":""},model:{value:(_vm.form.u_name),callback:function ($$v) {_vm.$set(_vm.form, "u_name", $$v)},expression:"form.u_name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Phone","type":"number","error-messages":errors,"required":""},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors,"required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"input-7-1","label":"Address","error-messages":errors,"required":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-file-input',{attrs:{"accept":"image/*","label":"Change Image","required":"","type":"file"},on:{"change":_vm.onFile}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}})],1)],1)],1)],2),_c('div',{staticClass:"text-center mt-3"},[_c('v-btn',{staticClass:"ma-3",attrs:{"dark":""},on:{"click":function($event){return _vm.Back()}}},[_vm._v("Back")]),_c('v-btn',{staticClass:"ma-3",attrs:{"color":"teal accent-3"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }