// import store from '@/store'
import blog from '@/views/admin/seos/index'
// import Category from '@/components/datatables/category'
// import seoss from '@/components/datatables/seos'
import seosForm from '@/views/admin/seos/form'
import seosShow from '@/views/admin/seos/show'
import seosEdit from '@/views/admin/seos/edit'
import AdminGuard from '@/helpers/guard'

export const seos = [
  {path: "/seos",component: blog, name: "product",
},

{path: "/seos/create",component: seosForm, beforeEnter: AdminGuard },
{path: "/seos/show/:code",component: seosShow, beforeEnter: AdminGuard },
{path: "/seos/:code/edit",component: seosEdit, beforeEnter: AdminGuard },
// {path: "/products/update",component: Stock, beforeEnter(to, from, next) {
//   if (token && isadmin){
//     next()
//   }else{
//     next('/')
//   }
// }},

]
