// import store from '@/store'
import dashboard from '@/views/admin/general/dashboard'
import orders from '@/views/admin/orders'
import show from '@/views/admin/orders/show'
import AdminGuard from '@/helpers/guard'


// var token = store.getters.Token
// var isadmin = store.getters.isadmin = true
export const general = [
  {path: "/dashboard",component: dashboard, beforeEnter: AdminGuard },
  {path: "/orders",component: orders, beforeEnter: AdminGuard },
  {path: "/orders/show/:code",component: show, beforeEnter: AdminGuard }
]
